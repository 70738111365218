<template>
  <div>
    <div class="vx-row">
      <!-- Your statistics card components here -->
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <chartjs-bar-chart></chartjs-bar-chart>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <chartjs-bar-chart></chartjs-bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
// Import necessary components and libraries
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data() {
    return {
      data: {
        labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],
        datasets: [{
          label: "Population (millions)",
          backgroundColor: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
          data: [2478, 5267, 734, 784, 433]
        }]
      },
      options: {
        legend: { display: false },
        title: {
          display: true,
          text: 'Predicted world population (millions) in 2050'
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  }
}
</script>

<style lang="scss">
/* Your styles here */
</style>
